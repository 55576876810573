import 'react-app-polyfill/stable'
import 'core-js'
import React, {useEffect, useState} from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import {config} from "./config";
import {cosmosConfig} from "./cosmosConfig";
import apiCalls from "./cosmosdb/cosmosAPIs";

import {EventType, PublicClientApplication} from "@azure/msal-browser";

//const [userEmail, setUserEmail] = useState('examole@ccma.com');

const pca = new PublicClientApplication({
    auth:{
        clientId: '229e9ece-8096-4783-bd18-8656c8a71adc',
        authority: 'https://login.microsoftonline.com/fcb5eb25-2f24-4dc5-a952-3346b8237d12',
        redirectUri: '/'
    }
})

//useEffect(() => {
//    window.localStorage.setItem('CCMA_USER_EMAIL', userEmail)
//}, [userEmail])

pca.addEventCallback(event=>{
    if(event.eventType === EventType.LOGIN_SUCCESS){
        console.log("event from index",event.payload.account)
        const ccmaUsername = event.payload.account.username
        //setUserEmail(ccmaUsername)
        setTimeout(() => {
            
            apiCalls.setAuditUserName(ccmaUsername)
            window.localStorage.setItem('CCMA_USER_EMAIL', ccmaUsername)
            sessionStorage.setItem('CCMA_USER_EMAIL', ccmaUsername);
            console.log(ccmaUsername)
            pca.setActiveAccount(event.payload.account)
        },3000)
      
    }
})


createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App msalInstance={pca} />
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
