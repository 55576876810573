import { LogLevel } from "@azure/msal-browser";

export const config={
    auth:{
        authority: "https://login.microsoftonline.com/fcb5eb25-2f24-4dc5-a952-3346b8237d12",
        // Client Application Id from Azure environments
        clientId: '229e9ece-8096-4783-bd18-8656c8a71adc',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
    },
    cache:{
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    },
    system: {
        // loggerOptions: {
        //     loggerCallback: (level, message, containsPii) => {
        //         if (containsPii) {
        //             return;
        //         }
        //         switch (level) {
        //             case LogLevel.Error:
        //                 console.error(message);
        //                 return;
        //             case LogLevel.Info:
        //                 console.info(message);
        //                 return;
        //             case LogLevel.Verbose:
        //                 console.debug(message);
        //                 return;
        //             case LogLevel.Warning:
        //                 console.warn(message);
        //                 return;
        //             default:
        //                 return;
        //         }
        //     }
        // }
    }
}

export const loginRequest ={
    scopes: ['user.read']
}