var cosmosConfig = {}

cosmosConfig.endpoint = 'https://dev-bc-subsidy-comos-db.documents.azure.com:443/'
cosmosConfig.key = 'v19hjaL5KxC1VE7HTQvWNfj1Q8D7zzsIkpeTY29FGuTHBWgfelHckTdWz2AZAdk7UONgCK0M5fDJACDboZTHjw=='

cosmosConfig.database = {
  id: 'dev-bc-db'
}

cosmosConfig.casesContainer = {
  id: 'cases'
}

cosmosConfig.bcContainer = {
  id: 'bargainingCouncils'
}

cosmosConfig.invoiceContainer = {
  id: 'invoices'
}

cosmosConfig.subsidyRatesContainer = {
  id: 'subsidyRates'
}

cosmosConfig.items = {
    bc1: {
        id: '1',
        partitionKey: 'qaBargainingKey',
        name: 'Knockturnal QA Council',
        shortName: 'Knockturnal QA',
        primaryAddress: '123 Main Street',
        secondaryAddress: '125 Main Street',
        contactName: 'Knockturnal QA',
        contactEmail: 'qa@ccma.org.za',
        bankName: 'JP Morgan',
        accountName: 'Knockturnal QA',
        accountType: 'Cheque',
        accountNumber: 10346630808,
        branchName: 'Jan Smuts Ave',
        branchCode: 508005
    },
    rate1: {
      id: '1',
      partitionKey: 'qaSubsidyRates',
      subsidyRate: 169.6,
      dateInitiated: "29-01-2024",
    }
}

module.exports = cosmosConfig