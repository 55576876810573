import React, { Component, Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import {config, loginRequest} from "./config";
import { EventType } from '@azure/msal-browser';
import {MsalProvider,useMsal, useIsAuthenticated} from "@azure/msal-react";



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// const makeAPICall = async () => {
//     try {
//         const response = await fetch('http://localhost:8080/', {mode:'cors'});
//         const data = await response.json();
//         console.log({ data })
//     }
//     catch (e) {
//         console.log(e)
//     }
// }
// useEffect(() => {
//     makeAPICall();
// }, [])


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))

function App({msalInstance}){



    return (
        <MsalProvider instance={msalInstance}>
            <HashRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route exact path="/login" name="Login Page" element={<Login />} />
                        <Route path="*" name="Home" element={<DefaultLayout />} />
                    </Routes>
                </Suspense>
            </HashRouter>
        </MsalProvider>
    )
}

export default App
